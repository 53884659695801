@media only screen and (min-width: 992px) {
  .integrations-card {
      margin-left: 2vw;
      margin-right: 2vw;
      margin-top: 3vh;
      margin-bottom: 3vh;
      width: calc(50% - 20px);
  }

  .integrations-card-header {
    padding-left: 5px;
    padding-right: 5px;
    width: calc(50% - 20px);
    display:inline-block;
  }
}

.integration-status {
  padding-top: 5%;
}

.integrations-card-header {
  width: 100%;
  text-align:center;
}

.integration-icon {
  margin-left: 5px;
  margin-right: 5px;
  width: calc(50% - 20px);
  display: inline-block;
}

.integration-title {
  margin-top:0%;
  display:inline-block;
}

.connect-button {
  margin-top: 5%;
  width: 100%;
}
