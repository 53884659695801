.success-page-container {
 text-align:center;
 margin-top: 10vh;
}

.integration-success-icon {
  font-size: 5rem;
  margin-bottom: 10px; 
}

.success-page-back-button {
  margin-top: 5vh;
}
