@media only screen and (min-width: 992px) {
  .receiving-number-list {
      margin-left: auto;
      margin-right: auto;
      margin-top: 3vh;
      margin-bottom: 3vh;
      width: calc(90% - 20px);
      border-radius: 10px; 
  }

  .admin-list-button {
    width: 100%;
  }
}

.receiving-numbers-add-button {
  margin-top: 5%;
  margin-left: 5%;
  margin-right: 60px;
}

.receiving-numbers-top-bar {
  display: flex; 
  justify-content: space-between;
  align-items: end; 
}

.receiving-numbers-filter {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-top: 5%;
  margin-left: 5%;
  margin-right: 5%;
}

.receiving-number-card {
  width: 100%; 
  display: flex; 
  justify-content: space-between;
  align-items: center; 
}

.breadcrumb {
  margin-top: 5%;
  margin-left: 5%; 
}
