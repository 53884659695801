.login-background {
  --background: url('https://d9hhrg4mnvzow.cloudfront.net/www.rolloverrep.com/7e45f975-unsplash-7brhzmwxn08-common-yarn_11hc2jf000000000000028.jpg') no-repeat center center / cover;
}

.login-background-overlay {
  background-color: rgba(1, 1, 1, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.login-area {
  padding-top: 25vh;
  padding-left: 20vw;
  padding-right: 20vw;
}

.rollover-rep-logo-login {
  margin-bottom: 5vh;
  margin-left: 7vw;
  margin-right: 7vw;
}

.sign-up-text {
  text-align: center;
  color: var(--ion-color-medium);
}
