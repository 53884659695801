.integration-step-container {
  margin-left: 2vw;
  margin-right: 2vw;
  margin-top: 3vh;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  padding: 20px;
}

.integration-step-title {
  width: 100%; 
  text-align:center;
  margin-bottom: 40px;  
}

.integration-step-save-option {
  width: 80%; 
  display: flex; 
  justify-content: space-between;
  margin:auto;  
}

.integration-step-input {
  width: 50%; 
  border: 2px solid var(--ion-color-light); 
  border-radius: 10px; 
  margin-right: 10px;
}

.integration-step-accordion-header {
  border: 1px solid black; 
  border-radius:10px; 
  margin-bottom: 10px;
}

.help-section {
  position: relative;
  display: inline-block;
  margin-top: 10px;
  cursor: default; 
  margin:auto; 
  margin-top: 10px; 
}

.help-text {
  color: var(--ion-color-dark);
  text-decoration: underline;
}

.tooltip {
  visibility: hidden;
  width: 70vw;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  position: absolute;
  z-index: 10;
  top: 100%; /* Position below the text */
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.tooltip img {
  width: 100%;
  height: auto;
  display: block;
}

.help-section:hover .tooltip {
  visibility: visible;
}

.integration-step-accordion-group {
  margin-top: 50px !important; 
  width: 80%; 
  margin: auto; 
}

.integration-step-accodion-label {
  font-weight: bold; 
  font-family: "Libre Baskerville", serif;
}

.queue-label {
  display:flex; 
  justify-content: space-between; 
}

.queue-label-header {
  font-weight:bold; 
}

.integration-step-save-button {
  width: 100%; 
}