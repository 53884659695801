@media only screen and (min-width: 992px) {
  .admin-list {
      margin-left: auto;
      margin-right: auto;
      margin-top: 10vh;
      margin-bottom: 3vh;
      width: calc(90% - 20px);
      border-radius: 10px; 

  }

  .admin-list-button {
    width: 100%;
  }

  .integrations-card-header {
    padding-left: 5px;
    padding-right: 5px;
    width: calc(50% - 20px);
    display:inline-block;
  }
}

.integration-status {
  padding-top: 5%;
}

.integrations-card-header {
  width: 100%;
  text-align:center;
}

.integration-icon {
  margin-left: 5px;
  margin-right: 5px;
  width: calc(50% - 20px);
  display: inline-block;
}

.integration-title {
  margin-top:0%;
  display:inline-block;
}

.connect-button {
  margin-top: 5%;
  width: 100%;
}
